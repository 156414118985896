import React, { useRef, useEffect, useState } from "react";

export default function Hero2D() {
	const canvasRef = useRef(null);
	const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);
	const [canvasHeight, setCanvasHeight] = useState(window.innerHeight);
	const [lines, setLines] = useState(100);
	const [animation, setAnimation] = useState(0);
	const isMobile = window.innerWidth < 750;
	const colorLines = '#2e2e2e'

	const handleMouseMove = (e) => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		ctx.strokeStyle = colorLines;
		const cursorX = (e.clientX - window.innerWidth / 2) * -2;
		const lineWidth = canvas.width / (lines * 2);
		const lineX = cursorX - canvas.width / 2;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		if (isMobile) {
			setLines(25);
		}
		for (let i = 0; i < lines * 1.2; i++) {
			const x = i * ((canvas.width * 2) / (lines * 2));
			ctx.beginPath();
			ctx.moveTo(x + lineX / 40, 0);
			ctx.lineTo(x + lineX / 40, canvas.height);
			ctx.lineWidth = lineWidth;
			ctx.stroke();
		}
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		if (isMobile) {
			let animationFrameId;
			const animate = () => {
				animationFrameId = requestAnimationFrame(animate);
				setAnimation((animation) => animation + 0.0001);
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.strokeStyle = colorLines;
				for (let i = 0; i < lines; i++) {
					const lineX = i * ((canvas.width * 2) / (lines * 2));
					ctx.beginPath();
					ctx.moveTo(
						lineX + Math.sin(animation) * (canvas.width / 2) * -0.1,
						0,
					);
					ctx.lineTo(
						lineX + Math.sin(animation) * (canvas.width / 2) * 0.1,
						canvas.height,
					);
					ctx.lineWidth = canvas.width / (lines * 2);
					ctx.stroke();
				}
			};
			animate();
			return () => cancelAnimationFrame(animationFrameId);
		} else {
			ctx.strokeStyle = colorLines;
			for (let i = 0; i < lines; i++) {
				const lineX = i * ((canvas.width * 2) / (lines * 2));
				ctx.beginPath();
				ctx.moveTo(lineX + (0 - canvas.width / 2) / 40, 0);
				ctx.lineTo(lineX + (0 - canvas.width / 2) / 40, canvas.height);
				ctx.lineWidth = canvas.width / (lines * 2);
				ctx.stroke();
			}
			canvas.addEventListener("mousemove", handleMouseMove);
		}

		window.addEventListener("resize", () => {
			setCanvasWidth(window.innerWidth);
			setCanvasHeight(window.innerHeight);
		});

		return () => {
			canvas.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("resize", () => {});
		};
	}, [handleMouseMove, canvasWidth, canvasHeight, animation, isMobile]);

	return (
		<canvas
			ref={canvasRef}
			width={canvasWidth}
			height={canvasHeight}
			style={{ background: "transparent" }}
		/>
	);
}
