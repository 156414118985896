import React, { useState } from 'react';

export default function Portfolio() {

    return(
        <div className='portfolio_wrap' id='portfolio'>
            <div className='portfolio'>
                <h2>Portfolio Coming Soon</h2>
            </div>
        </div>
    )
}
