import React from "react";

import Hero2D from "./Hero2D";

const Hero = () => {
	return (
		<div className='hero_wrapper'>
			<div className='hero_content'>
				<h2>Fantasma RIP</h2>
				<h2>Fantasma RIP</h2>
				<h2>Fantasma RIP</h2>
				<h5>Art & Tech Collective from Mexico - Since 2020</h5>
				<p>
					founded by{" "}
					<a href='https://animanoir.xyz' target={"_blank"} rel={"noreferrer"}>
						animanoir
					</a>{" "}
					and{" "}
					<a href='https://omarolaes.com' target={"_blank"} rel={"noreferrer"}>
						ömer
					</a> with the purpose of creating a space for experimentation and exploration of the digital arts.
				</p>
				<Hero2D />
			</div>
		</div>
	);
};

export default Hero;
